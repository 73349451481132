import getAllRoutes from './AppApiRoutes'
import type {
  CartItem,
  Material,
  PropertiesLookupValue
} from '~~/composables/useMenuModel'

export const useCartMethods = () => {
  async function getMaterialsCount(): Promise<any> {
    const cart = useCartStore()
    const { data } = await useBasicFetch<ApiResponse<number>>(
      getAllRoutes(useMenuModel().isAuthLoggedIn()).cartRoutes
        .getMaterialsCountWithToggle,
      {
        query: {
          client_uuid: cart.getUuid()
        }
      }
    )
    cart.setMaterialsCount(data.value?.data)
  }
  async function syncMaterials(): Promise<any> {
    const cart = useCartStore()

    return await useBasicFetch(
      getAllRoutes(useMenuModel().isAuthLoggedIn()).cartRoutes
        .getCartWithToggle,
      {
        query: {
          client_uuid: cart.getUuid()
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      }
    )
  }
  async function clearMaterials(): Promise<any> {
    const cart = useCartStore()
    return await useBasicFetch(
      getAllRoutes(useMenuModel().isAuthLoggedIn()).cartRoutes
        .clearCartWithToggle,
      {
        method: 'DELETE',
        query: {
          client_uuid: cart.getUuid()
        }
      }
    )
  }

  function reducePropertiesLookupValues(
    propertiesLookupValues?: PropertiesLookupValue[]
  ) {
    const props = propertiesLookupValues?.reduce((r: any, a) => {
      return [
        ...r,
        {
          property_definition_id: a.property_id || a.property_definition_id,
          value_id: a.value_id
        }
      ]
    }, [])

    return props?.length ? props : []
  }
  async function addMaterial(
    materialId: number,
    quantity?: number,
    propertiesLookupValues?: PropertiesLookupValue[],
    branchId?: number
  ): Promise<any> {
    const cart = useCartStore()
    const propertiesLookups = reducePropertiesLookupValues(
      propertiesLookupValues
    )

    return await useBasicFetch(
      getAllRoutes(useMenuModel().isAuthLoggedIn()).cartRoutes
        .addMaterialWithToggle,
      {
        method: 'POST',
        body: {
          client_uuid: cart.getUuid(),
          material_id: materialId,
          branch_id: branchId,
          properties_lookup_values: propertiesLookupValues?.length
            ? propertiesLookupValues
            : undefined,
          propertiesLookupValues: propertiesLookups?.length
            ? propertiesLookups
            : undefined,
          count: quantity || 1
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      }
    )
  }
  async function setTableToStore(
    storeID: number | string,
    TableID: any,
    branchId?: number
  ): Promise<any> {
    const cart = useCartStore()
    return await useBasicFetch(
      getAllRoutes(
        useMenuModel().isAuthLoggedIn()
      ).cartRoutes.setTableToStoreWithToggle(storeID),
      {
        method: 'POST',
        body: {
          client_uuid: cart.getUuid(),
          table_id: TableID,
          store_id: storeID,
          branch_id: branchId
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      }
    )
  }
  async function removeMaterialsStore(
    storeID: number,
    branchId?: number
  ): Promise<any> {
    const cart = useCartStore()
    return await useBasicFetch(
      getAllRoutes(
        useMenuModel().isAuthLoggedIn()
      ).cartRoutes.removeMaterialsStoreCartWithToggle(storeID),
      {
        method: 'DELETE',
        body: {
          client_uuid: cart.getUuid(),
          store_id: storeID,
          branch_id: branchId
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      }
    )
  }
  async function editMaterial(data: object): Promise<any> {
    const cart = useCartStore()

    return await useBasicFetch(
      getAllRoutes(useMenuModel().isAuthLoggedIn()).cartRoutes
        .editMaterialFromCartWithToggle,
      {
        method: 'POST',
        body: {
          client_uuid: cart.getUuid(),
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      }
    )
  }
  async function editAddonsInCart(data: object, itemId: number): Promise<any> {
    const cart = useCartStore()

    return await useBasicFetch(
      getAllRoutes(
        useMenuModel().isAuthLoggedIn()
      ).cartRoutes.editAddonsCartWithToggle(itemId),
      {
        method: 'POST',
        body: {
          client_uuid: cart.getUuid(),
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      }
    )
  }
  async function addMaterialWithAddons(data: object): Promise<any> {
    const cart = useCartStore()

    return await useBasicFetch(
      getAllRoutes(useMenuModel().isAuthLoggedIn()).cartRoutes
        .addMaterialWithAddonWithToggle,
      {
        method: 'POST',
        body: {
          client_uuid: cart.getUuid(),
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      }
    )
  }
  async function deleteMaterial(data: object): Promise<any> {
    const cart = useCartStore()
    const isAuthLoggedIn = useMenuModel().isAuthLoggedIn()
    return await useBasicFetch(
      getAllRoutes(isAuthLoggedIn).cartRoutes.removeFromCartWithToggle,
      {
        method: 'DELETE',
        body: {
          client_uuid: isAuthLoggedIn ? undefined : cart.getUuid(),
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      }
    )
  }
  async function editOffer(data: object): Promise<any> {
    const cart = useCartStore()

    const isAuthLoggedIn = useMenuModel().isAuthLoggedIn()
    return await useBasicFetch(
      getAllRoutes(isAuthLoggedIn).cartRoutes.editCartOfferWithToggle,
      {
        method: 'POST',
        body: {
          client_uuid: isAuthLoggedIn ? undefined : cart.getUuid(),
          ...data
        },
        transform: (data: SuccessResponse<any>) => {
          return handleRequestResult(data)
        }
      }
    )
  }

  function handleRequestResult(RequestResul: any) {
    const { cartModel } = useMenuModel()
    const dataLocal = RequestResul.data || {}
    dataLocal.cart_items = dataLocal?.cart_items?.map(cartModel) || []
    return dataLocal
  }

  function matchItems(
    items: CartItem[],
    materialProp: Material,
    addonsAttributes?: any,
    groupOfferId?: number
  ): any {
    const material = { ...materialProp }

    return items?.reduce((r: any, a: CartItem, indexLocal: number) => {
      if (a.material.id !== material.id) {
        return r
      }

      if ((a.material_group_offer_id ?? null) !== (groupOfferId ?? null)) {
        return r
      }

      if (!((a.addons?.length || 0) === (addonsAttributes?.length || 0))) {
        return r
      }
      if (a.addons?.length && addonsAttributes?.length) {
        let matches = 0
        a.addons.forEach((extraAttributesItem) => {
          const i = addonsAttributes.findIndex(
            (etraAttributesMaterial: any) => {
              return extraAttributesItem.id === etraAttributesMaterial.id
            }
          )
          if (Number.isSafeInteger(i) && i >= 0) {
            matches++
          }
        })
        if (matches === a.addons!.length) {
          r.push({
            item: a,
            index: indexLocal
          })
          return r
        } else {
          return r
        }
      } else {
        r.push({
          item: a,
          index: indexLocal
        })
        return r
      }
    }, [])
  }
  return {
    matchItems,
    editMaterial,
    editOffer,
    deleteMaterial,
    syncMaterials,
    setTableToStore,
    editAddonsInCart,
    clearMaterials,
    getMaterialsCount,
    addMaterial,
    reducePropertiesLookupValues,
    addMaterialWithAddons,
    removeMaterialsStore
  }
}
